import https from "./https"
import store from "@/store"

const order = {
  getOrders(params) {
    const organization = store.state.general.organization
    return https.get('admin/organizations/' + organization.id + '/orders', {params})
  },
  getOrder(id) {
    const organization = store.state.general.organization
    return https.get('admin/organizations/' + organization.id + '/orders/' + id)
  },
  updateOrder(id, params) {
    const organization = store.state.general.organization
    return https.put('admin/organizations/' + organization.id + '/orders/' + id, params)
  },
  createOrder(params) {
    const organization = store.state.general.organization
    return https.post('admin/organizations/' + organization.id + '/orders/', params)
  },
  deleteOrder(id) {
    const organization = store.state.general.organization
    return https.delete('admin/organizations/' + organization.id + '/orders/' + id)
  }
}

export default order
