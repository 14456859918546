var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.showLoading,"rounded":"sm"}},[_c('div',{staticClass:"row d-flex mb-4 mb-xl-2 justify-content-between"},[_c('h4',{staticClass:"col-12 col-xl-2 mb-1 mb-xl-0 font-weight-bold"},[_vm._v(" 訂單列表 ")]),(_vm.showFilter)?_c('div',{staticClass:"\n        col-12 col-xl-8\n        d-flex\n        flex-column flex-xl-row\n        align-items-start align-items-xl-center\n      "},[_c('b-button',{staticClass:"flex-shrink-0 mb-2 mb-xl-0 mr-3 py-2",attrs:{"variant":"primary","to":{
        name: 'OrderCreate',
        query: { merchant_id: this.selectedMerchantID },
      }}},[_c('i',{staticClass:"fa fa-plus",attrs:{"aria-hidden":"true"}}),_vm._v("新增訂單 ")]),_c('b-form-select',{attrs:{"disabled":_vm.merchants.length == 2,"options":_vm.merchants},on:{"change":_vm.handleSelectMerchant},model:{value:(_vm.selectedMerchantID),callback:function ($$v) {_vm.selectedMerchantID=$$v},expression:"selectedMerchantID"}}),_c('b-input-group',{staticClass:"ml-0 ml-xl-4"},[_c('b-form-input',{attrs:{"placeholder":"訂單編號或外部編號"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.fetchOrders(_vm.currentPage)}},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}}),_c('b-input-group-append',[_c('b-button',{on:{"click":function($event){return _vm.fetchOrders(_vm.currentPage)}}},[_c('i',{staticClass:"fa fa-search"})])],1)],1)],1):_vm._e()]),_c('b-table',{attrs:{"responsive":"","striped":"","hover":"","items":_vm.orders,"fields":_vm.filteredFields},on:{"sort-changed":_vm.handleSort},scopedSlots:_vm._u([{key:"cell(branch)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.branch ? data.item.branch.name / data.item.branch.id : "")+" ")]}},{key:"cell(order_number)",fn:function(data){return [_c('router-link',{attrs:{"to":{
        name: 'OrderDetail',
        params: {
          order_id: data.item.id,
        },
      }}},[_vm._v(" "+_vm._s(data.item.order_number)+" ")])]}},{key:"cell(outer_order_number)",fn:function(data){return [_c('router-link',{attrs:{"to":{
        name: 'OrderDetail',
        params: {
          order_id: data.item.id,
        },
      }}},[_vm._v(" "+_vm._s(data.item.outer_order_number)+" ")])]}},{key:"cell(customer_name)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.customer ? data.item.customer.name : "")+" ")]}},{key:"cell(action)",fn:function(data){return [(_vm.checkPermission([_vm.consts.ORDER_LIST_VIEW]))?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"primary","to":{
        name: 'OrderDetail',
        params: {
          order_id: data.item.id,
        },
      }}},[_vm._v("查看 ")]):_vm._e(),(_vm.checkPermission([_vm.consts.ORDER_MODIFY]))?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"inverse-warning","to":{
        name: 'OrderEdit',
        params: {
          order_id: data.item.id,
        },
      }}},[_vm._v("編輯 ")]):_vm._e(),(_vm.checkPermission([_vm.consts.ORDER_DELETE]))?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"inverse-danger"},on:{"click":function($event){return _vm.handleDelete(data.item.id)}}},[_vm._v("刪除 ")]):_vm._e()]}}])}),_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"margin-top":"80px"}},[_c('b-pagination',{attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"align":"center"},on:{"change":_vm.fetchOrders},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }