<template>
  <b-overlay :show="showLoading" rounded="sm">
    <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
      <h4 class="col-12 col-xl-2 mb-1 mb-xl-0 font-weight-bold">
        訂單列表
      </h4>
      <div class="
          col-12 col-xl-8
          d-flex
          flex-column flex-xl-row
          align-items-start align-items-xl-center
        "
        v-if="showFilter"
      >
        <b-button class="flex-shrink-0 mb-2 mb-xl-0 mr-3 py-2" variant="primary" :to="{
          name: 'OrderCreate',
          query: { merchant_id: this.selectedMerchantID },
        }"><i class="fa fa-plus" aria-hidden="true"></i>新增訂單
        </b-button>
        <b-form-select
          v-model="selectedMerchantID"
          :disabled="merchants.length == 2"
          :options="merchants"
          @change="handleSelectMerchant"
          ></b-form-select>
        <b-input-group class="ml-0 ml-xl-4">
          <b-form-input placeholder="訂單編號或外部編號" v-model="keyword"
            v-on:keyup.enter="fetchOrders(currentPage)"></b-form-input>
          <b-input-group-append>
            <b-button @click="fetchOrders(currentPage)"><i class="fa fa-search"></i></b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
    </div>
    <b-table responsive striped hover :items="orders" :fields="filteredFields" @sort-changed="handleSort">
      <template #cell(branch)="data">
        {{ data.item.branch ? data.item.branch.name / data.item.branch.id : "" }}
      </template>

      <template #cell(order_number)="data">
        <router-link :to="{
          name: 'OrderDetail',
          params: {
            order_id: data.item.id,
          },
        }">
          {{ data.item.order_number }}
        </router-link>
      </template>
      <template #cell(outer_order_number)="data">
        <router-link :to="{
          name: 'OrderDetail',
          params: {
            order_id: data.item.id,
          },
        }">
          {{ data.item.outer_order_number }}
        </router-link>
      </template>
      <template #cell(customer_name)="data">
        {{ data.item.customer ? data.item.customer.name : "" }}
      </template>
      <template #cell(action)="data">
        <b-button class="ml-2" variant="primary" v-if="checkPermission([consts.ORDER_LIST_VIEW])" :to="{
          name: 'OrderDetail',
          params: {
            order_id: data.item.id,
          },
        }">查看
        </b-button>
        <b-button class="ml-2" variant="inverse-warning" v-if="checkPermission([consts.ORDER_MODIFY])" :to="{
          name: 'OrderEdit',
          params: {
            order_id: data.item.id,
          },
        }">編輯
        </b-button>
        <b-button class="ml-2" variant="inverse-danger" @click="handleDelete(data.item.id)"
          v-if="checkPermission([consts.ORDER_DELETE])">刪除
        </b-button>
      </template>
    </b-table>
    <div class="d-flex justify-content-center" style="margin-top: 80px">
      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center"
        @change="fetchOrders"></b-pagination>
    </div>
  </b-overlay>
</template>

<script>
import { format } from "date-fns";
import orderApi from "@/apis/order";
import merchantApi from "@/apis/merchant";
import * as consts from "@/consts";
import PermissionChecker from "@/utils/PermissionChecker";

export default {
  props: {
    showFilter: {
      type: Boolean,
      default: true,
    },
    excludeFields: {
      type: Array,
      default: () => [],
    },
    upperCustomerId: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      consts,
      keyword: null,
      showLoading: false,
      selectedMerchantID: null,
      merchants: [
        { value: null, text: "請選擇" },
      ],
      currentPage: 1,
      totalRows: 1,
      perPage: 10,
      fields: [
        {
          key: "order_number",
          label: "訂單編號",
          sortable: true,
        },
        {
          key: "outer_order_number",
          label: "外部編號",
          sortable: true,
        },
        {
          key: "customer_name",
          label: "顧客姓名",
        },
        {
          key: "total",
          label: "總價(含稅)",
        },
        {
          key: "memo",
          label: "消費備註",
        },
        {
          key: "order_at",
          label: "下單時間",
          sortable: true,
          formatter: (value) => {
            if (value) {
              return format(new Date(value), 'yyyy-MM-dd HH:mm');
            }
            return '';
          },
        },
        {
          key: "status_at",
          label: "最新狀態時間",
          sortable: true,
          formatter: (value) => {
            if (value) {
              return format(new Date(value), 'yyyy-MM-dd HH:mm');
            }
            return '';
          },
        },
        {
          key: "action",
          label: "管理",
        },
      ],
      orders: [],
      permissionChecker: new PermissionChecker(),
      sortBy: 'order_at',
      orderBy: "desc"
    };
  },
  computed: {
    filteredFields() {
      return this.fields.filter((field) => !this.excludeFields.includes(field.key))
    },
  },
  mounted() {
    this.fetchMerchants();
  },
  methods: {
    handleSort(ctx) {
      this.sortBy = ctx.sortBy;
      this.orderBy = ctx.sortDesc ? "desc" : "asc";
      this.currentPage = 1;
      this.fetchOrders();
    },
    async fetchOrders(page) {
      if (!this.selectedMerchantID) return

      this.showLoading = true;

      try {
        const params = {
          page: page || this.currentPage,
          order_by: this.orderBy,
        };
        if (this.sortBy) params.sort_by = this.sortBy;
        if (this.keyword) params.keyword = this.keyword;
        if (this.selectedMerchantID) params.merchant_id = this.selectedMerchantID
        if (this.upperCustomerId) params.customer_id = this.upperCustomerId

        const { data } = await orderApi.getOrders(params);

        this.orders = data.data;
        this.totalRows = data.meta.total;
        this.perPage = data.meta.per_page;
      } catch (error) {
        console.error(error);
        alert("取得訂單資料錯誤");
      }
      this.showLoading = false;
    },
    async fetchMerchants() {
      const { data } = await merchantApi.list();

      this.merchants = [
        { value: null, text: "請選擇" },
        ...data.data.map((merchant) => {
          return {
            value: merchant.id,
            text: `${merchant.name} (${merchant.type_name})`,
          };
        }),
      ];

      let defaultMerchant
      if (data.data.length == 1) {
        defaultMerchant = data.data[0]
      } else {
        defaultMerchant = data.data.filter(merchant => merchant.type == 'LINE').shift()
      }

      if (this.$route.query.merchant_id) {
        this.selectedMerchantID = this.$route.query.merchant_id

        var hasMerchant = this.merchants.find((item) => {
          return item.value === this.selectedMerchantID
        })

        if (!hasMerchant) {
          this.$swal
            .fire({
              type: "warning",
              title: "查無此通路",
              confirmButtonText: "確定",
              confirmButtonColor: "#d33",
              reverseButtons: true,
            })
          return
        }

        this.fetchOrders()
        return
      }

      if (defaultMerchant) {
        this.selectedMerchantID = defaultMerchant.id;
        this.fetchOrders()
      }

    },
    async handleDelete(id) {
      this.$swal
        .fire({
          type: "warning",
          title: "你確定要刪除嗎？",
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              const response = await orderApi.deleteOrder(id);
              if (response.status === 200) {
                await this.$swal.fire({
                  title: "成功",
                  type: "success",
                  text: "刪除成功",
                });
                await this.fetchProducts();
              }
            } catch (error) {
              this.$swal("失敗", error.response.data.message, "error");
            }
          }
        });
    },
    checkPermission(permissions) {
      return this.permissionChecker.check(permissions);
    },
    handleSelectMerchant() {
      this.$router.replace({ query: { merchant_id: this.selectedMerchantID } })

      this.fetchOrders()
    }
  },
};
</script>

<style>
.modal-backdrop {
  opacity: 0.5;
}
</style>
